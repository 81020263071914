import AuthRoute from 'components/AuthRoute';
import GuestRoute from 'components/GuestRoute';
import DashboardLoader from 'components/shared/DashboardLoader';
import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { Routes } from 'react-router';
import { Route, useLocation } from 'react-router-dom';
import { loginPath, profilePath, votePagesPath } from 'utils/paths';

const $ = window.$;
const $window = $(window);
const $body = $('body');

const LazyLogin = lazy(() => import('pages/Login'));
const LazySignUp = lazy(() => import('pages/SignUp/SignUp'));
const LazyForgotPassword = lazy(() => import('pages/ForgotPassword/ForgotPassword'));
const LazyPasswordReset = lazy(() => import('pages/PasswordReset'));
const LazyHome = lazy(() => import('pages/Home'));
const LazyCloseAccount = lazy(() => import('pages/CloseAccount'));
const LazyProfile = lazy(() => import('pages/Profile/Profile'));
const LazyAccountSubscribe = lazy(() => import('pages/AccountSubscribe/AccountSubscribe'));
const LazyVotePages = lazy(() => import('pages/VotePages'));
const LazyCreateVotePage = lazy(() => import('pages/CreateVotePage/CreateVotePage'));
const LazyVotePage = lazy(() => import('pages/VotePage/VotePage'));
const LazyNotFound = lazy(() => import('pages/NotFound'));

const Fallback = () => <DashboardLoader />;

const App: React.FC = () => {
  const { pathname } = useLocation();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    window.scrollTo(0, 0);
    if ($window.width() < 1025) {
      $body.toggleClass('enlarge-menu');
    }
  }, [pathname]);

  return (
    <Suspense fallback={<Fallback />}>
      <Routes>
        <Route path="/" element={<AuthRoute />}>
          <Route index element={<LazyHome />} />
          {/*<Route path="/about" element={<About />} />*/}
          <Route path="/close-account" element={<LazyCloseAccount />} />
          <Route path={profilePath} element={<LazyProfile />} />
          <Route path="/account/subscribe" element={<LazyAccountSubscribe />} />
          <Route path="/vote-pages" element={<LazyVotePages />} />
          <Route path="/vote-pages/create" element={<LazyCreateVotePage />} />
          <Route path={votePagesPath} element={<LazyVotePage />} />
        </Route>
        <Route path="/" element={<GuestRoute />}>
          <Route path={loginPath} element={<LazyLogin />} />
          <Route path="/sign-up" element={<LazySignUp />} />
          <Route path="/forgot-password" element={<LazyForgotPassword />} />
        </Route>
        <Route path="/password-reset/:token" element={<LazyPasswordReset />} />
        <Route path="*" element={<LazyNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default App;
