import { PayloadAction } from '@reduxjs/toolkit';
import iziToast from 'izitoast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { doLogout } from 'store/auth';
import { ApiResponse } from 'types/types';
import api from 'utils/api';
import Auth from 'utils/auth';
import { apiV1Url } from 'utils/config';
import { getDashboardSettingsEndpoint, getVotePagesEndpoint } from 'utils/endpoints';
import { handleApiError, handleApiResponse } from 'utils/utilities';

import {
  callFetchAuthSettings,
  callFetchVotePages,
  setDashboardSettings,
  setInitializeFailed,
  setInitializingDashboard,
  setVotePages,
} from './index';

export default function* dashboardSaga() {
  yield takeLatest(callFetchAuthSettings, getAuthSettings);
  yield takeLatest(callFetchVotePages, fetchVotePages);
}

function promptActivateAccount(accountId: string) {
  const btnId = 'activateRequestBtn' + Math.random().toString(16).split('.')[1];

  iziToast.info({
    progressBar: false,
    timeout: false,
    title: 'Your account is not yet active',
    message: 'Use the activation link sent to your email or request a new one.',
    transitionIn: 'fadeInDown',
    transitionOut: 'fadeOutUp',
    buttons: [
      [
        `<button id="${btnId}">Request</button>`,
        function (instance, toast) {
          const btn = document.getElementById(btnId) as HTMLButtonElement;
          let oldContent = '';
          if (btn) {
            oldContent = btn.innerHTML;
            btn.innerHTML = '<i class="fa fa-spin fa-sync-alt"></i>';
            btn.disabled = true;
          }

          Auth.post(apiV1Url(`accounts/${accountId}/activation-mail/request`))
            .then(
              handleApiResponse((data) => {
                iziToast.success({
                  message: data.message || 'An activation link has been sent to your email.',
                });
                instance.hide({}, toast, 'requestButton');
              })
            )
            .catch(
              handleApiError((data) => {
                iziToast.error({ message: data.message || 'Could not send link, try again.' });
                if (btn) {
                  btn.innerHTML = oldContent;
                  btn.disabled = false;
                }
              })
            );
        },
        true,
      ], // true to focus
    ],
  });
}

function* getAuthSettings() {
  yield all([put(setInitializeFailed(false)), put(setInitializingDashboard(true))]);

  try {
    const { data, status } = (yield call(api.request, getDashboardSettingsEndpoint())).data;
    if (status !== 1) {
      throw new Error('Log in not successful');
    }

    const { account, subscription, ...restSettings } = data;
    yield put(setDashboardSettings({ initialized: true, account, subscription, settings: restSettings }));
    yield put(callFetchVotePages(account.id));

    if (!account.is_active) {
      promptActivateAccount(account.id);
    }
  } catch (e) {
    yield put(setInitializeFailed());
    yield put(doLogout());
  }

  yield put(setInitializingDashboard(false));
}

function* fetchVotePages({ payload: accountId }: PayloadAction<string>) {
  try {
    const { data, status } = (yield call(api.request<ApiResponse>, getVotePagesEndpoint(accountId))).data;

    if (status !== 1) {
      throw new Error('Could not fetch vote pages');
    }
    yield put(setVotePages({ votePages: data.vote_pages, fetched: true }));
  } catch (e) {
    yield put(setVotePages({ votePages: [], fetched: false }));
  }
}
