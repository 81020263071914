import DashboardLoader from 'components/shared/DashboardLoader';
import { ReactElement, useEffect } from 'react';
import { Outlet } from 'react-router';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { authTokenSelector, loggedInSelector } from 'store/auth/selectors';
import { callFetchAuthSettings } from 'store/dashboard';
import {
  initializedSelector,
  initializeFailedSelector,
  initializingDashboardSelector,
} from 'store/dashboard/selectors';

const AuthRoute: React.FC<{ children?: ReactElement }> = ({ children, ...rest }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const authToken = useAppSelector(authTokenSelector);
  const initialized = useAppSelector(initializedSelector);
  const initializeFailed = useAppSelector(initializeFailedSelector);
  const initializingDashboard = useAppSelector(initializingDashboardSelector);
  const loggedIn = useAppSelector(loggedInSelector);

  useEffect(() => {
    if (!initializingDashboard && !initialized && authToken) dispatch(callFetchAuthSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializingDashboard, initialized, authToken]);

  if (!loggedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (initializingDashboard || (!initializingDashboard && initializeFailed) || !initialized) {
    return <DashboardLoader />;
  }

  return children ? children : <Outlet />;
};

export default AuthRoute;
