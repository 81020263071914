import { VotePageTheme } from 'types/models';
import buildRequest from 'utils/utils';

export const getDashboardSettingsEndpoint = () => buildRequest('/api/v1/auth/account/dashboard/settings');

export const getVotePagesEndpoint = (accountId: string) => buildRequest(`/api/v1/accounts/${accountId}/vote-pages`);

export const getNomineesEndpoint = (votePageId: string) => buildRequest(`/api/v1/vote-pages/${votePageId}/nominees`);

export const getCategoriesEndpoint = (votePageId: string) =>
  buildRequest(`/api/v1/vote-pages/${votePageId}/categories`);

export const postPasswordConfirmEndpoint = (account_id: string, password: string) =>
  buildRequest('/api/v1/accounts/password/confirm', 'post', { account_id, password });

export const postLoginAccountEndpoint = (account: string, password: string) =>
  buildRequest('/api/v1/auth/account', 'post', { account, password }, { withCredentials: true });

export const postLogoutEndpoint = () =>
  buildRequest('/api/v1/auth/account/logout', 'post', undefined, { withCredentials: true });

export const postRefreshTokenEndpoint = () =>
  buildRequest('/api/v1/auth/account/refresh', 'post', undefined, { withCredentials: true });

export const postSignUpEndpoint = (data: {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  username: string;
}) => buildRequest('/api/v1/accounts', 'post', data, { withCredentials: true });

export const deleteAccountEndpoint = (accountId: string, { password, reason }: { password: string; reason: string }) =>
  buildRequest(`/api/v1/accounts/${accountId}`, 'delete', { password, reason });

export const postCreateVotePageEndpoint = (data: { vote_type: string; theme: Partial<VotePageTheme>; name: string }) =>
  buildRequest(`/api/v1/vote-pages`, 'post', data);

export const getAccountSubscriptionEndpoint = (accountId: string) =>
  buildRequest(`/api/v1/accounts/${accountId}/subscription`);

export const getSubscriptionsEndpoint = () => buildRequest('/api/v1/subscriptions');

export const postPaymentInitSubscriptionEndpoint = (data: {
  account_id: string;
  subscription_id: string;
  billing_type: string;
  duration: number;
}) => buildRequest('/api/v1/payment/init/subscription', 'post', data);

export const postAccountSubscribeEndpoint = (data: {
  account_id: string;
  subscription_id: string;
  billing_type: string;
  duration: number;
  transaction_id: string;
}) => buildRequest('/api/v1/account/subscribe', 'post', data);

export const postPaymentCancelEndpoint = (data: { ref: string }) =>
  buildRequest('/api/v1/payment/cancel', 'post', data);

export const postInitiatePasswordResetEndpoint = (account: string) =>
  buildRequest('/api/v1/auth/account/initiate-password-reset', 'post', { account });

export const getVoteTrendEndpoint = (votePageId: string) =>
  buildRequest(`/api/v1/votes/trend?vote_page_id=${votePageId}`);
