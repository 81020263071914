import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAccount, ISubscription, IVotePage } from 'types/models';
import { mapToObject } from 'utils/utilities';

import { DashboardState, SetAuthSettingsPayload } from './types';

export const dashboardSliceName = 'dashboard';

const INITIAL_STATE: DashboardState = {
  initialized: false,
  votePagesFetched: false,
  initializingDashboard: false,
  initializeFailed: false,
  account: null,
  subscription: null,
  votePages: {},
  settings: {
    templates: {},
    vote_stages: {},
    vote_types: {},
    currencies: [],
  },
};

const dashboardSlice = createSlice({
  name: dashboardSliceName,
  initialState: INITIAL_STATE satisfies DashboardState as DashboardState,
  reducers: {
    setDashboardSettings(state, { payload }: PayloadAction<SetAuthSettingsPayload>) {
      state.account = payload.account;
      state.subscription = payload.subscription;
      state.initialized = payload.initialized;
      state.settings = payload.settings;
    },
    setInitializingDashboard(state, action: PayloadAction<boolean>) {
      state.initializingDashboard = action.payload;
    },
    setVotePages(state, action: PayloadAction<{ votePages: IVotePage[]; fetched: boolean }>) {
      state.votePages = mapToObject(action.payload.votePages);
      state.votePagesFetched = action.payload.fetched;
    },
    addVotePage(state, { payload }: PayloadAction<{ votePage: IVotePage }>) {
      const { votePage } = payload;
      state.votePages = { ...state.votePages, [votePage.id]: votePage };
    },
    updateVotePage(state, { payload }: PayloadAction<{ votePageId: string; votePageUpdate: Partial<IVotePage> }>) {
      const { votePageId, votePageUpdate } = payload;
      state.votePages[votePageId] = { ...state.votePages[votePageId], ...votePageUpdate };
    },
    removeVotePage(state, { payload: votePageId }: PayloadAction<string>) {
      const { [votePageId]: removedVotePage, ...restVotePages } = state.votePages;
      state.votePages = restVotePages;
    },
    updateAccount(state, { payload }: PayloadAction<{ accountValues: Partial<IAccount> }>) {
      state.account = { ...(state.account as IAccount), ...payload.accountValues };
    },
    updateSubscription(state, { payload: subscription }: PayloadAction<Partial<ISubscription>>) {
      state.subscription = { ...(state.subscription as ISubscription), ...subscription };
    },
    setInitializeFailed(state, { payload: initializeFailed }: PayloadAction<boolean | undefined>) {
      state.initializeFailed = initializeFailed ?? true;
    },
    clearDashboardSettings(state) {
      const { account, subscription, settings } = INITIAL_STATE;
      state.initialized = false;
      state.account = account;
      state.subscription = subscription;
      state.settings = settings;
    },
  },
});

const { actions, reducer: dashboardReducer } = dashboardSlice;

export const callFetchAuthSettings = createAction(`${dashboardSliceName}/callFetchAuthSettings`);
export const callFetchVotePages = createAction<string>(`${dashboardSliceName}/callFetchVotePages`);

export const {
  setDashboardSettings,
  setInitializingDashboard,
  setVotePages,
  addVotePage,
  updateVotePage,
  removeVotePage,
  updateAccount,
  updateSubscription,
  setInitializeFailed,
  clearDashboardSettings,
} = actions;

export default dashboardReducer;
