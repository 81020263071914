import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { authSliceName } from './index';

export const authSelector = (state: RootState): RootState[typeof authSliceName] => state[authSliceName];

export const loggedInSelector = createSelector(authSelector, (authSlice) => authSlice.loggedIn);

export const loggingInSelector = createSelector(authSelector, (authSlice) => authSlice.loggingIn);

export const authTokenSelector = createSelector(authSelector, (authSlice) => authSlice.authToken);

export const loginErrorSelector = createSelector(authSelector, (authSlice) => authSlice.loginError);

export const isSigningUpSelector = createSelector(authSelector, (authSlice) => authSlice.isSigningUp);

export const signUpSuccessSelector = createSelector(authSelector, (authSlice) => authSlice.signUpSuccess);

export const signUpErrorSelector = createSelector(authSelector, (authSlice) => authSlice.signUpError);

export const redirectAfterAuthSelector = createSelector(authSelector, (authSlice) => authSlice.redirectAfterAuth);
