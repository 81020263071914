export const dndContainerId = (id: string) => `category_${id}`;
export const isDndContainerId = (id: string) => id?.indexOf('category_') === 0;
export const dndCategoryId = (id: string) => id?.replace('category_', '');

/**
 * Check if two values are equal after converting them to string
 * @param value1
 * @param value2
 */
export const strEql = (value1: unknown, value2: unknown) => value1?.toString() === value2?.toString();
