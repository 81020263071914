export default function loadTawkTo() {
  // eslint-disable-next-line
  var Tawk_API: any = Tawk_API || {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/57a890a50e299d9644791219/default';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode?.insertBefore(s1, s0);
  })();
}
