import api from 'utils/api';

/**
 * @deprecated use api from 'utils/api' instead
 */
const Auth = {
  get(url, data = {}, config: any = {}) {
    config.params = Object.assign(config.params || {}, data);
    return this._request('get', url, data, config);
  },

  post(url, data = {}, config = {}) {
    return this._request('post', url, data, config);
  },

  patch(url, data = {}, config = {}) {
    return this._request('patch', url, data, config);
  },

  put(url, data = {}, config = {}) {
    return this._request('put', url, data, config);
  },

  delete(url, data = {}, config = {}) {
    return this._request('delete', url, data, config);
  },

  _request(method, url, data, config) {
    return api.request({
      method,
      url,
      data,
      ...config,
    });
  },
};

export default Auth;
