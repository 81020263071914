import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { categoryLimitSelector, nomineeLimitSelector } from 'store/dashboard/selectors';
import api from 'utils/api';
import { getCategoriesEndpoint, getNomineesEndpoint } from 'utils/endpoints';

import {
  callFetchCategories,
  callFetchNominees,
  setCategories,
  setFetchingCategories,
  setFetchingNominees,
  setNominees,
} from './index';

export default function* managePageSaga() {
  yield takeEvery(callFetchCategories, fetchCategories);
  yield takeEvery(callFetchNominees, fetchNominees);
}

function* fetchCategories({ payload: votePageId }: PayloadAction<string>) {
  const categoryLimit = yield select(categoryLimitSelector);
  yield put(setFetchingCategories(true));
  yield put(setCategories({ categories: [], categoryLimit }));

  try {
    const responseData = (yield call(api.request, getCategoriesEndpoint(votePageId))).data;
    yield put(setCategories({ categories: responseData.data.categories, categoryLimit }));
  } catch (e) {}
  yield put(setFetchingCategories(false));
}

function* fetchNominees({ payload: votePageId }: PayloadAction<string>) {
  const nomineeLimit = yield select(nomineeLimitSelector);
  yield put(setFetchingNominees(true));
  yield put(setNominees({ nominees: [], nomineeLimit }));

  try {
    const responseData = (yield call(api.request, getNomineesEndpoint(votePageId))).data;
    yield put(setNominees({ nominees: responseData.data.nominees, nomineeLimit }));
  } catch (e) {}

  yield put(setFetchingNominees(false));
}
