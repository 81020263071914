import { useMemo } from 'react';

const lenMap = {
  xl: '5rem',
  lg: '3rem',
  md: '1.6rem',
  sm: '0.9rem',
  xs: '0.4rem',
};

interface ISpinnerProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  type?: 'grow' | 'border';
}

const Spinner: React.FC<ISpinnerProps> = (props) => {
  const { size = 'sm', type = 'border' } = props;

  const dim = useMemo(() => {
    const len = lenMap[size] || lenMap.sm;
    return { width: len, height: len };
  }, [size]);

  return <span className={`spinner-${type}`} role="status" aria-hidden="true" style={dim} />;
};

export default Spinner;
