export const rejectApiData = data => {
  const err = new Error();
  err.response = { data };
  throw err;
};

export const handleApiResponse = callback => response => {
  const { data } = response;
  const callbackReturn = callback(data, response);
  return Promise.resolve(callbackReturn || data);
};

export const handleApiError = callback => error => {
  const response = error.response || { data: {} };
  const { data } = response;
  const callbackReturn = callback(data, response, error);
  console.error(error);
  return Promise.resolve(callbackReturn || data);
};
