import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SignUpData } from 'store/auth/types';
import { clearDashboardSettings } from 'store/dashboard';
import { ApiResponse } from 'types/types';
import api from 'utils/api';
import { postLoginAccountEndpoint, postLogoutEndpoint, postSignUpEndpoint } from 'utils/endpoints';

import {
  clearAuthState,
  doLogin,
  doLogout,
  doSignUp,
  loginUser,
  logoutUser,
  setLoggingIn,
  setLoginError,
  setSignUpState,
} from './index';

export default function* authSaga() {
  yield takeLatest(doLogin, loginUserSaga);
  yield takeLatest(doLogout, logoutUserSaga);
  yield takeLatest(doSignUp, signUpUserSaga);
  yield takeLatest(clearAuthState, clearUserAuthState);
}

function* loginUserSaga({ payload }: PayloadAction<{ account: string; password: string }>) {
  yield all([put(setLoggingIn(true)), put(setLoginError(null))]);
  try {
    const { account, password } = payload;

    const response = yield call(api.request<ApiResponse>, postLoginAccountEndpoint(account, password));
    const { data, status } = response.data;

    if (status === 1) {
      yield all([put(loginUser(data.access_token)), put(setLoggingIn(false))]);
    } else {
      const aError = new AxiosError('Login not successful');
      aError.response = response;
      throw aError;
    }
  } catch (e: any) {
    yield all([put(setLoggingIn(false)), put(setLoginError(e.response?.data?.message || 'Could not log in'))]);

    if (!e.response) console.error(e);
  }
}

function* signUpUserSaga({ payload }: PayloadAction<SignUpData>) {
  try {
    yield put(setSignUpState({ isSigningUp: true }));
    const response = yield call(api.request, postSignUpEndpoint(payload));
    const { data, message } = response.data;

    yield put(setSignUpState({ signUpSuccess: message || 'Your account has been created' }));
    yield put(loginUser(data.access_token));
  } catch (e: any) {
    yield put(
      setSignUpState({ isSigningUp: false, signUpError: e.response?.data?.message || 'Could not create account' })
    );

    if (!e.response) console.error(e);
  }
}

function* logoutUserSaga() {
  try {
    yield call(api.request, postLogoutEndpoint());
  } catch (e: any) {
    if (!e.response) console.error('logout failed', e);
  }
  yield call(clearUserAuthState);
}

function* clearUserAuthState() {
  yield all([put(clearDashboardSettings()), put(logoutUser())]);
}
