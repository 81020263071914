import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, SignUpData } from 'store/auth/types';
import { authTokenKey } from 'utils/api';

export const authSliceName = 'auth';

const initialToken = localStorage.getItem(authTokenKey) || null;

const INITIAL_STATE: AuthState = {
  loggedIn: Boolean(initialToken),
  loggingIn: false,
  authToken: initialToken,
  loginError: null,
  isSigningUp: false,
  signUpSuccess: null,
  signUpError: null,
  redirectAfterAuth: true,
};

const authSlice = createSlice({
  name: authSliceName,
  initialState: INITIAL_STATE satisfies AuthState as AuthState,
  reducers: {
    loginUser(state, { payload: authToken }: PayloadAction<string>) {
      localStorage.setItem(authTokenKey, authToken);
      state.authToken = authToken;
      state.loggedIn = true;
    },
    setLoginError(state, { payload: loginError }: PayloadAction<string | null>) {
      state.loginError = loginError;
    },
    setLoggingIn(state, { payload: loggingIn }: PayloadAction<boolean>) {
      state.loggingIn = loggingIn;
    },
    logoutUser(state) {
      localStorage.removeItem(authTokenKey);
      state.authToken = null;
      state.loggedIn = false;
    },
    setSignUpState(
      state,
      {
        payload: { isSigningUp = false, signUpSuccess = null, signUpError = null },
      }: PayloadAction<{ isSigningUp?: boolean; signUpSuccess?: string | null; signUpError?: string | null }>
    ) {
      state.isSigningUp = isSigningUp;
      state.signUpSuccess = signUpSuccess;
      state.signUpError = signUpError;
    },
    setRedirectAfterAuth(state, { payload }: PayloadAction<boolean>) {
      state.redirectAfterAuth = payload;
    },
  },
});

const { actions, reducer: authReducer } = authSlice;

export const doLogin = createAction<{ account: string; password: string }>(`${authSliceName}/doLogin`);
export const doSignUp = createAction<SignUpData>(`${authSliceName}/doSignUp`);
export const doLogout = createAction(`${authSliceName}/doLogout`);
export const clearAuthState = createAction(`${authSliceName}/clearAuthState`);

export const { loginUser, setLoginError, setLoggingIn, logoutUser, setSignUpState, setRedirectAfterAuth } = actions;

export default authReducer;
