import { arrayMove } from '@dnd-kit/sortable';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICategory, INominee } from 'types/models';
import { PartialExcept } from 'types/types';
import { strEql } from 'utils/helpers';
import { mapToObject } from 'utils/utilities';

import { ManagePageState, MoveNomineesPayload, SetCategoriesPayload, SetNomineesPayload } from './types';

export const managePageSliceName = 'managePage';

const INITIAL_STATE: ManagePageState = {
  fetchingCategories: false,
  fetchingNominees: false,
  categories: {},
  nominees: {},
  sortedCategoryIds: [],
  categoryNominees: {},
  movedNomineesCategoryIds: [],
  categoriesReordered: false,
  hasHiddenCategories: false,
  hasHiddenNominees: false,
};

const managePageSlice = createSlice({
  name: managePageSliceName,
  initialState: INITIAL_STATE satisfies ManagePageState as ManagePageState,
  reducers: {
    setCategories(state, { payload }: PayloadAction<SetCategoriesPayload>) {
      let hasHiddenCategories = state.hasHiddenCategories;
      const categories = mapToObject(payload.categories);
      // NOTE: if categories is not sorted from backend, sort them here
      let sortedCategories: ICategory[] = payload.categories;
      if (payload.categoryLimit && sortedCategories.length > payload.categoryLimit) {
        sortedCategories = sortedCategories.slice(0, payload.categoryLimit);
        hasHiddenCategories = true;
      }
      const sortedCategoryIds: string[] = [];
      const categoryNominees = {};
      sortedCategories.forEach((category) => {
        sortedCategoryIds.push(category.id?.toString());
        categoryNominees[category.id] = state.categoryNominees[category.id] || [];
      });

      state.categories = categories;
      state.sortedCategoryIds = sortedCategoryIds;
      state.hasHiddenCategories = hasHiddenCategories;
      state.categoryNominees = categoryNominees;
    },

    setNominees(state, { payload }: PayloadAction<SetNomineesPayload>) {
      const { nominees, nomineeLimit } = payload;
      const newCategoryNominees = {};

      if (!nominees) return;

      let hasHiddenNominees = state.hasHiddenNominees;
      // you can sort nominees here is it isn't sorted from the backend nominees.sort()

      for (let ix in nominees) {
        const nominee = nominees[ix];

        if (!newCategoryNominees[nominee.category_id]) {
          newCategoryNominees[nominee.category_id] = [];
        }
        if (nomineeLimit && newCategoryNominees[nominee.category_id].length >= nomineeLimit) {
          hasHiddenNominees = true;
        } else {
          newCategoryNominees[nominee.category_id].push(nominee.id?.toString());
        }
      }

      state.nominees = mapToObject(nominees);
      state.categoryNominees = { ...state.categoryNominees, ...newCategoryNominees };
      state.hasHiddenNominees = hasHiddenNominees;
    },

    setFetchingCategories(state, { payload: fetching }: PayloadAction<boolean>) {
      state.fetchingCategories = fetching;
    },

    setFetchingNominees(state, { payload: fetching }: PayloadAction<boolean>) {
      state.fetchingNominees = fetching;
    },

    addNominee(state, { payload: nominee }: PayloadAction<INominee>) {
      if (!nominee) return;
      const nominees = { ...state.nominees, [nominee.id]: nominee };

      // NOTE: if we want to be able to add nominees at custom position, you can place the nominee at the position here, instead of the end
      const categoryNominees = {
        ...state.categoryNominees,
        [nominee.category_id]: [...(state.categoryNominees[nominee.category_id] || []), nominee.id?.toString()],
      };
      state.nominees = nominees;
      state.categoryNominees = categoryNominees;
    },

    updateNominee(state, { payload: nominee }: PayloadAction<PartialExcept<INominee, 'id' | 'category_id'>>) {
      if (!nominee) return;

      const prevNominee = state.nominees[nominee.id];
      let categoryNominees = state.categoryNominees;

      if (prevNominee.category_id?.toString() !== nominee.category_id?.toString()) {
        // move nominee to new category list
        categoryNominees = {
          ...categoryNominees,
          [prevNominee.category_id]: categoryNominees[prevNominee.category_id]?.filter(
            (nomId) => nomId !== nominee.id?.toString()
          ),
          [nominee.category_id]: [...categoryNominees[nominee.category_id], nominee.id?.toString()],
        };
      }

      state.nominees[nominee.id] = { ...state.nominees[nominee.id], ...nominee };
      state.categoryNominees = categoryNominees;
    },

    removeNominee(state, { payload: nomineeId }: PayloadAction<string>) {
      const nominee = state.nominees[nomineeId];
      if (!nominee) return;

      const { [nomineeId]: removedNominee, ...nominees } = state.nominees;
      const categoryId = nominee.category_id;
      const newNomineesIds = state.categoryNominees[categoryId].filter((nomId) => nomId !== nominee.id.toString());
      const categoryNominees = { ...state.categoryNominees, [categoryId]: newNomineesIds };

      state.nominees = nominees;
      state.categoryNominees = categoryNominees;
    },

    addCategory(state, { payload: category }: PayloadAction<ICategory>) {
      if (!category) return;
      state.categories = { ...state.categories, [category.id]: category };
      state.sortedCategoryIds = [...state.sortedCategoryIds, category.id?.toString()];
      state.categoryNominees[category.id] = [];
    },

    updateCategory(state, { payload: category }: PayloadAction<PartialExcept<ICategory, 'id'>>) {
      if (!category) return;
      state.categories[category.id] = { ...state.categories[category.id], ...category };
    },

    removeCategory(state, { payload: categoryId }: PayloadAction<string>) {
      const { [categoryId]: removedCategory, ...categories } = state.categories;
      const { [categoryId]: removedCatMap, ...categoryNominees } = state.categoryNominees;
      state.categories = categories;
      state.categoryNominees = categoryNominees;
      state.sortedCategoryIds = state.sortedCategoryIds.filter((catId) => !strEql(catId, categoryId));
    },

    moveNominee(state, { payload }: PayloadAction<MoveNomineesPayload>) {
      const { nomineeId, categoryId, prevCategoryId, position, prevPosition } = payload;
      const sameCategory = categoryId?.toString() === prevCategoryId?.toString();
      let categoryNominees = state.categoryNominees;

      if ((!position && position !== 0) || (!prevPosition && prevPosition !== 0)) return;

      if (sameCategory) {
        // likely onDragEnd, because nominee is already in the category before the update

        if (position === prevPosition) return; // no need to update
        categoryNominees[categoryId] = arrayMove(categoryNominees[categoryId], prevPosition, position);
      } else {
        categoryNominees[categoryId] = [
          ...categoryNominees[categoryId].slice(0, position),
          categoryNominees[prevCategoryId][prevPosition],
          ...categoryNominees[categoryId].slice(position, categoryNominees[categoryId].length),
        ];
        categoryNominees[prevCategoryId] = categoryNominees[prevCategoryId]?.filter((nomId) => nomId !== nomineeId);
      }
      const moveNomineesCategorySet = new Set(state.movedNomineesCategoryIds);
      moveNomineesCategorySet.add(categoryId);
      moveNomineesCategorySet.add(prevCategoryId);

      state.categoryNominees = categoryNominees;
      state.movedNomineesCategoryIds = Array.from(moveNomineesCategorySet);
    },

    clearReorderedNominees(state) {
      state.movedNomineesCategoryIds = [];
    },

    moveCategory(state, { payload }: PayloadAction<{ position: number; prevPosition: number }>) {
      let { position, prevPosition } = payload;

      if (prevPosition < 0 || prevPosition > state.sortedCategoryIds.length) {
        console.error(`Cannot move, no category at position: ${prevPosition}`);
        return;
      }
      if (position < 0 || position > state.sortedCategoryIds.length) {
        console.error(`Cannot move category to illegal position: ${position}`);
        return;
      }
      if (position === prevPosition) return;

      state.sortedCategoryIds = arrayMove(state.sortedCategoryIds, prevPosition, position);
      state.categoriesReordered = true;
    },

    setCategoriesReordered(state, { payload: categoriesReordered = false }: PayloadAction<boolean>) {
      state.categoriesReordered = categoriesReordered;
    },
  },
});

const { actions, reducer: managePageReducer } = managePageSlice;

export const callFetchCategories = createAction<string>(`${managePageSliceName}/callFetchCategories`);
export const callFetchNominees = createAction<string>(`${managePageSliceName}/callFetchNominees`);

export const {
  setCategories,
  setNominees,
  setFetchingCategories,
  setFetchingNominees,
  addNominee,
  updateNominee,
  removeNominee,
  addCategory,
  updateCategory,
  removeCategory,
  moveNominee,
  clearReorderedNominees,
  moveCategory,
  setCategoriesReordered,
} = actions;

export default managePageReducer;
