import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import authReducer, { authSliceName } from 'store/auth';
import authSaga from 'store/auth/sagas';
import dashboardReducer, { dashboardSliceName } from 'store/dashboard';
import managePageReducer, { managePageSliceName } from 'store/managePage';
import managePageSaga from 'store/managePage/sagas';

import dashboardSaga from './dashboard/sagas';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    [authSliceName]: authReducer,
    [dashboardSliceName]: dashboardReducer,
    [managePageSliceName]: managePageReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([sagaMiddleware]),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(authSaga);
sagaMiddleware.run(dashboardSaga);
sagaMiddleware.run(managePageSaga);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export default store;
