import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { dashboardSliceName } from './index';

export const dashboardSelector = (state: RootState): RootState[typeof dashboardSliceName] => state[dashboardSliceName];

export const accountSelector = createSelector(dashboardSelector, (dashSlice) => dashSlice.account);

export const votePagesSelector = createSelector(dashboardSelector, (dashSlice) => dashSlice.votePages);

export const votePageCountSelector = createSelector(votePagesSelector, (votePages) => Object.keys(votePages).length);

export const getVotePageSelector = (votePageId: string) =>
  createSelector(votePagesSelector, (votePages) => votePages[votePageId]);

export const getVotePageByIdentifierSelector = (identifier: string) =>
  createSelector(votePagesSelector, (votePages) => {
    if (!identifier) return null;
    return Object.values(votePages).find((page) => page.identifier === identifier) || null;
  });

export const settingsSelector = createSelector(dashboardSelector, (dashSlice) => dashSlice.settings);

export const voteStagesSelector = createSelector(settingsSelector, (settings) => settings.vote_stages);

export const voteTypesSelector = createSelector(settingsSelector, (settings) => settings.vote_types);

export const templatesSelector = createSelector(settingsSelector, (settings) => settings.templates);

export const currenciesSelector = createSelector(settingsSelector, (settings) => settings.currencies);

export const initializingDashboardSelector = createSelector(
  dashboardSelector,
  (dashSlice) => dashSlice.initializingDashboard
);

export const votePagesFetchedSelector = createSelector(dashboardSelector, (dashSlice) => dashSlice.votePagesFetched);

export const initializedSelector = createSelector(dashboardSelector, (dashSlice) => dashSlice.initialized);

export const initializeFailedSelector = createSelector(dashboardSelector, (dashSlice) => dashSlice.initializeFailed);

export const subscriptionSelector = createSelector(dashboardSelector, (dashSlice) => dashSlice.subscription);

export const userLimitsSelector = createSelector(subscriptionSelector, (subscription) => subscription?.limits);

export const categoryLimitSelector = createSelector(userLimitsSelector, (limits) => limits?.category || 0);

export const nomineeLimitSelector = createSelector(userLimitsSelector, (limits) => limits?.nominee || 0);

export const votePageLimitSelector = createSelector(userLimitsSelector, (limits) => limits?.vote_page || 0);
