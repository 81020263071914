import Spinner from 'components/shared/Spinner';
import React from 'react';

// TODO: design DashboardLoader
const DashboardLoader = () => {
  return (
    <div style={{ textAlign: 'center', padding: '170px 20px' }}>
      <Spinner size="lg" />
      <h1>Setting Up Dashboard</h1>
    </div>
  );
};

export default DashboardLoader;
