import axios, { AxiosError, AxiosResponse } from 'axios';
import iziToast from 'izitoast';
import store from 'store';
import { clearAuthState, loginUser } from 'store/auth';
import { authTokenSelector } from 'store/auth/selectors';
import { ApiResponse } from 'types/types';
import { postRefreshTokenEndpoint } from 'utils/endpoints';

let authTokenOrFetching: string | boolean = false;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

export const authTokenKey = 'token';

api.interceptors.request.use(
  function apiInterceptRequestSuccess(config) {
    // Get the access token from wherever you've stored it (e.g., a global state, localStorage, or a function)

    const accessToken = authTokenSelector(store.getState());

    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },
  function apiInterceptRequestError(error) {
    // Something happened in setting up the request that triggered an Error
    console.log('Api Error', error);

    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function apiInterceptResponseSuccess(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  async function apiInterceptResponseError(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    // TODO: we may need to handle race condition for when multiple endpoints are called with expired access token

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.error('Api Response Error', error);

      const statusCode = error.response?.status;
      if (statusCode === 403) {
        let otherRequestFetchingToken = false;

        const originalRequest = error.config;

        const doClearAuth = () => {
          iziToast.error({ message: 'Your session has expired.' });
          store.dispatch(clearAuthState());
        };

        // if the request authentication fails on retry, then clear the auth state
        if (originalRequest._retry) {
          doClearAuth();
          return;
        }

        // TODO: REVIEW THIS WAITING FOR TOKEN LOGIC

        // another request is already fetching the new token
        if (authTokenOrFetching === true) {
          otherRequestFetchingToken = true;
          // wait for new token to be gotten
          const otherRequestSuccessfullyGotToken = await new Promise((resolve) => {
            setInterval(() => {
              if (authTokenOrFetching === true) return; // keep checking

              if (authTokenOrFetching === false) return resolve(false); // could not get new token

              resolve(!!authTokenOrFetching); // true if the token was gotten
            }, 250);
          });
        }

        originalRequest._retry = true;

        try {
          // if no other request first tried to get the token, attempt to get it, and indicate to others.
          if (!otherRequestFetchingToken) {
            authTokenOrFetching = true;
            const response = await api.request(postRefreshTokenEndpoint());
            const { access_token } = response.data.data;
            authTokenOrFetching = access_token;

            store.dispatch(loginUser(access_token));
          }

          return api.request(originalRequest);
        } catch (er) {
          authTokenOrFetching = false;
          doClearAuth();
          return Promise.reject(er);
        }
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // and an instance of http.ClientRequest in node.js
      // console.error('Api Request Error', error);
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.error('Api Error', error);
    }

    return Promise.reject(error);
  }
);

export function getDataFromError<T extends ApiResponse>(error: AxiosError): T | null {
  if (!error.response) {
    console.error(error);
    return null;
  }
  return (error.response as AxiosResponse<T>)?.data || null;
}

export default api;
