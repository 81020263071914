import React, { ReactElement } from 'react';
import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import { loggedInSelector, redirectAfterAuthSelector } from 'store/auth/selectors';

const GuestRoute: React.FC<{ children?: ReactElement }> = ({ children }) => {
  const redirectAfterAuth = useAppSelector(redirectAfterAuthSelector);
  const loggedIn = useAppSelector(loggedInSelector);

  if (loggedIn && redirectAfterAuth) {
    return <Navigate to="/" />;
  }

  return children ? children : <Outlet />;
};

export default GuestRoute;
